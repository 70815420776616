import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

import Blog from './Blog';

const BlogCards = () => (
  <StaticQuery
    query={graphql`
      query{
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              fields {
                slug
                readingTime {
                  text
                }
              }
              htmlAst
              frontmatter {
                coverImage {
                  childImageSharp {
                    fluid(maxWidth: 1240) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                author
                date(formatString: "MMMM DD, YYYY")
                category
                shortTitle
                description
                shortDescription
              }
            }
          }
        }
      }`
    }
    render={data =>
      data.allMarkdownRemark.edges.map(({ node: blog }, i) => (
          <Blog
              author={blog.frontmatter.author}
              category={blog.frontmatter.category}
              date={blog.frontmatter.date}
              title={blog.frontmatter.shortTitle}
              description={blog.frontmatter.description}
              image={blog.frontmatter.coverImage}
              slug={blog.fields.slug}
              featured
              time={blog.fields.readingTime.text}
            />
        ))
    } />
);

export default BlogCards;
