import React from 'react';
import { Box } from '@rebass/emotion';
import Layout from '../components/layout';
import SEO from '../components/seo';

import Wrap from '../components/Wrap';
import BlogCards from '../modules/BlogCards';

const Blog = () => (
  <Layout dark>
    <SEO title="Citra. Our Blog" keywords={[`blog`, `ux`, `product`, `design`, `studio`]} />
    <Box bg="lightBeige" style={{ position: 'relative' }} py={['12%', '6%']}>
      <Wrap flexWrap="wrap">
        <Box ml={[0]} width={[1, 10/10]}>
          <BlogCards />
        </Box>
      </Wrap>
    </Box>
  </Layout>
);

export default Blog;
