import React from 'react';
import PropTypes from 'prop-types';
import { Box, Flex } from '@rebass/emotion';
import Link from 'gatsby-link';
import Image from 'gatsby-image';
import styled from '@emotion/styled';

import Heading from '../components/Heading';
import Text from '../components/Text';
import Author from '../components/Author';

const BlogLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

const Blog = ({
  author,
  date,
  title,
  category,
  description,
  slug,
  featured,
  time,
  image,
   ...props }) => {
  if (featured) {
    return (
      <BlogLink to={slug}>
        <Flex bg="white" data-aos="fade-up" flexWrap="wrap" alignItems="stretch">
          <Box width={[1, 1/2, 2/3]}>
            <Image style={{ height: '100%' }} fluid={image.childImageSharp.fluid} />
          </Box>
          <Box bg="white" width={[1, 1/2, 1/3]} p={5}>
            <Heading color="darkGray" fontSize={[4, 4, 5]} mt={0} mb={3}>{title}</Heading>
            <Text mb={5} fontSize={[0, 1]}>{description}</Text>
            <Author small date={date} person={author} />
          </Box>
        </Flex>
      </BlogLink>
    )
  } else {
    return (
      <Text>Hello</Text>
    )
  }
};

Blog.propTypes = {
  title: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default Blog;
