/** @jsx jsx */
import PropTypes from 'prop-types';
import { Box, Flex, Text } from '@rebass/emotion';
import { jsx } from '@emotion/core';

import ImageLoad from './ImageLoad';

const Author = ({ person, date, small, ...props }) => {
  const AuthorImg = () => {
    let imgUrl = '';
    if (person === 'Scott Simpson') {
      imgUrl = 'scott-avatar.jpg';
    }
    else if (person === 'Chetan Shenoy') {
      imgUrl = 'chetan-avatar.jpg';
    }
    return (
      <ImageLoad rounded imgsrc={imgUrl} imgalt={person} />
    )
  }
  return (
    <Flex alignItems="center" {...props}>
      <Box width={small ? '40px' : '50px'}>
        <AuthorImg />
      </Box>
      <Box ml={3}>
        <Text mb={1} fontSize={small ? [0, 1] : [1,2]}>{person}</Text>
        <Text fontSize={0}>{date}</Text>
      </Box>
    </Flex>
  );
};

Author.propTypes = {
  person: PropTypes.string.isRequired,
};

export default Author;
